import { createRoot } from 'react-dom/client'
import { Chat } from '../app'

createRoot(document.body)
  .render(<DocumentBody />, document.body)

function DocumentBody () {
  return (
    <>
      <header>
        <h1>
          <span style={{ backgroundColor: '#333', color: '#fff', padding: '.25rem' }}>speak</span>.codin.xyz
        </h1>
      </header>
      <Chat />
    </>
  )
}
